import React from 'react';
import { translate } from '../../helpers/translate';
import { Box, Typography } from '@material-ui/core';
import { ErrorMessage } from '../ErrorMessage';

type ICommunicationBreakdownProps = {
    reason: string;
};

const CommunicationBreakdown: React.FC<ICommunicationBreakdownProps> = React.memo(
    ({ reason }: ICommunicationBreakdownProps): JSX.Element | null => {
        if (!reason) return null;

        return (
            <ErrorMessage>
                <Box mt={-1}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        {translate('error')}
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        {translate('communicationBreakdown')}
                    </Typography>
                    <Typography variant="caption" component="p">
                        • {reason}
                    </Typography>
                </Box>
            </ErrorMessage>
        );
    },
);

CommunicationBreakdown.displayName = 'CommunicationBreakdown';

export { CommunicationBreakdown };
