import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

type ILoadingProps = {
    centered?: boolean;
};

const useStyles = makeStyles(() => ({
    centered: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

export const Loading: React.FC<ILoadingProps> = React.memo(
    ({ centered }: ILoadingProps): JSX.Element => {
        const classes = useStyles();

        return (
            <aside aria-hidden className={centered ? classes.centered : ''}>
                <CircularProgress />
            </aside>
        );
    },
);
