import { Chip } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { translate } from '../../helpers/translate';
import { truncateString } from '../../helpers/string';
import { useProductList } from '../../store/product-list';

export const FilterChips = (): ReactElement => {
    const {
        activeFacets,
        setFacet,
        searchTerm,
        setSearchTerm,
        clearAllFilters,
        selectedCategory,
        selectCategory,
    } = useProductList();

    const doRemoveAllFacets = (): void => {
        clearAllFilters();
    };

    const doRemoveFacet = (facetFamily: string, facetName = ''): void => {
        setFacet(facetFamily, facetName, false);
    };

    const doRemoveCategory = (): void => {
        selectCategory('');
    };

    const moreThanOneFilter = activeFacets.length + (searchTerm ? 1 : 0) + (selectedCategory ? 1 : 0) > 1;

    return (
        <>
            {moreThanOneFilter && (
                <Chip
                    label={translate('productFilters.removeAllFilters')}
                    onDelete={doRemoveAllFacets}
                    onClick={doRemoveAllFacets}
                />
            )}
            {selectedCategory && (
                <Chip
                    label={truncateString(
                        translate('productFilters.categoryChipLabel', { categoryName: selectedCategory }),
                        40,
                    )}
                    title={selectedCategory}
                    onDelete={doRemoveCategory}
                    onClick={doRemoveCategory}
                />
            )}
            {searchTerm && (
                <Chip
                    label={truncateString(translate('productFilters.searchChipLabel', { searchQuery: searchTerm }), 40)}
                    title={searchTerm}
                    onDelete={(): void => setSearchTerm('')}
                    onClick={(): void => setSearchTerm('')}
                />
            )}
            {activeFacets.map(
                (facet) =>
                    facet && (
                        <Chip
                            key={`${facet.FacetName}-${facet.Query?.Value}`}
                            label={facet.Query?.Name}
                            onDelete={(): void => doRemoveFacet(facet.FacetName, facet.Query?.Value)}
                            onClick={(): void => doRemoveFacet(facet.FacetName, facet.Query?.Value)}
                        />
                    ),
            )}
        </>
    );
};
