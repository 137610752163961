import React, { ReactElement } from 'react'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import {
  addHeightToImagePath,
  addWidthToImagePath,
} from '../../helpers/query-string'
import { Link } from 'gatsby'
import { VariantSelector } from '../VariantSelector'
import { translate } from '../../helpers/translate'
import { FilterProduct } from '../../types/generated/sitecore.interface'
import { Icon5G } from '../../icons/Icon5'
import { routes } from '../Router/routes'
import { StockStatusIndicator } from '../StockStatusIndicator/StockStatusIndicator'
import tdcColors from '@tdcerhverv/parrotfish/dist/colors-design-token.js'

type ProductCardProps = {
  product: FilterProduct
  has5GBadge: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    alignSelf: 'stretch',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  primaryArea: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  detailWrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  mainInfo: {
    flex: '1 0 auto',
  },
  mediaWrapper: {
    height: '160px',
    flex: '0 0 160px',
    marginTop: theme.spacing(3),
  },
  productImage: {
    maxHeight: 160,
    width: 'auto',
    margin: 'auto',
    objectFit: 'initial',
  },
  stockWrapper: {
    marginTop: theme.spacing(1.5),
  },
  productBadge: {
    position: 'absolute',
    left: '80%',
    fill: theme.palette.primary.light,
  },
  bottomWrapper: {
    alignSelf: 'flex-end',
  },
  brandName: {
    color: tdcColors.greyMediumDark,
  },
  exVatLabel: {
    color: tdcColors.greyMediumDark,
  },
}))

const ProductCard: React.FC<ProductCardProps> = React.memo(
  ({
    product: { Variants: variants = [] } = {},
    has5GBadge,
  }): ReactElement | null => {
    const classes = useStyles()
    const variantData = variants[0]
    if (!variantData) return null

    return (
      <Card className={classes.root}>
        <CardActionArea
          className={classes.primaryArea}
          component={Link}
          to={routes.productPage.build(
            variantData.VariantNo || '',
            variantData.Name,
            variantData.ColorName,
          )}
        >
          <div className={classes.mediaWrapper}>
            {has5GBadge && <Icon5G className={classes.productBadge} />}
            <CardMedia
              className={classes.productImage}
              image={addHeightToImagePath(
                addWidthToImagePath(variantData.Image?.Url, 300),
                200,
              )}
              title={variantData.Image?.AltText || ''}
              component="img"
            />
          </div>
          <CardContent className={classes.detailWrapper}>
            <Box className={classes.mainInfo} mb={2.5}>
              <Typography
                variant="h6"
                component="div"
                className={classes.brandName}
              >
                {variantData.Brand}
              </Typography>
              <Typography variant="h5" component="div">
                {variantData.Name}
              </Typography>
              {variantData.Delivery && (
                <aside className={classes.stockWrapper}>
                  <StockStatusIndicator
                    color={variantData.Delivery.Status}
                    text={variantData.Delivery.Text}
                  />
                </aside>
              )}
            </Box>
            <Grid
              container
              justifyContent="space-between"
              className={classes.bottomWrapper}
            >
              <Grid item>
                <Box mt={0.75}>
                  <VariantSelector
                    variants={variants}
                    selectedIndex={-1} // This is to disable the small checkmark.
                    limit
                  />
                </Box>
              </Grid>
              <Grid item>
                {variantData.FormattedPrice && (
                  <>
                    <Typography variant="h5" component="div" align="right">
                      {variantData.FormattedPrice}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      align="right"
                      className={classes.exVatLabel}
                    >
                      {translate('exVatLabel')}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  },
)

ProductCard.displayName = 'ProductCard'

export { ProductCard }
