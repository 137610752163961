import React from 'react';
import { useProductList } from '../../store/product-list';
import { makeStyles, Typography } from '@material-ui/core';
import { translate } from '../../helpers/translate';
import { FilterChips } from '../FilterChips/FilterChips';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(4.5),
    },
    header: {
        marginBottom: theme.spacing(2.5),
    },
    chips: {
        margin: theme.spacing(-0.5),
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export const FilterStatus: React.FC = () => {
    const { activeFacets, searchTerm, selectedCategory } = useProductList();
    const classes = useStyles();

    if (!activeFacets.length && !searchTerm && !selectedCategory) return null;

    return (
        <aside className={classes.root}>
            <Typography className={classes.header} variant="h6" component="h2">
                {translate('productFilters.header')}
            </Typography>
            <div className={classes.chips}>
                <FilterChips />
            </div>
        </aside>
    );
};
