import React from 'react'
import ProductOverview from '../components/ProductOverview/ProductOverview'
import Layout from '../Layout/Layout'
import Seo from '../components/Seo'
import { usePageViewAnalytics } from '../hooks/useAnalytics'

const IndexPage = () => {
  // Adobe Analytics - Global Variables
  usePageViewAnalytics({
    trackingPageInfo: {
      pageName: 'shop',
      siteSection: 'shop',
      subSection1: 'forsiden',
      pageType: 'product list',
      id: `shop-frontpage`,
      pageVariant: 'SMB',
    },
  })

  return (
    <Layout>
      <Seo lang="da" title={`Produkter`} description="TDC Erhverv Shop" />
      <ProductOverview />
    </Layout>
  )
}

export default IndexPage
