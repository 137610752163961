import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useThrottle } from '@react-hook/throttle'
import SearchIcon from '../../icons/generated/SearchIcon'
import { Box, Button, makeStyles } from '@material-ui/core'
import { useSearchSuggestions } from '../../hooks/use-search-suggestions'
import { useProductList } from '../../store/product-list'
import { translate } from '../../helpers/translate'
import CloseIcon from '../../icons/generated/CloseIcon'

const useStyles = makeStyles(({ spacing }) => ({
  inputRoot: {
    paddingTop: '5px !important', // Otherwise it looks all weird 'n stuff, at least in Firefox
  },
  searchIcon: {
    marginTop: 3,
    marginRight: spacing(1),
    '& > svg': {
      width: spacing(2),
      height: spacing(2),
    },
  },
  clearButton: {
    height: spacing(4),
    width: spacing(4),
    minWidth: 0,
  },
  clearIcon: {
    fontSize: '1.2em',
  },
}))

export const Search: React.FC = React.memo((): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [focus, setFocus] = useState(false)
  const [throttledInputValue, setThrottledInputValue] = useThrottle('', 2, true)
  const { suggestions, loading: loadingData } =
    useSearchSuggestions(throttledInputValue)
  const [rawInputValue, setRawInputValue] = useState('')
  const classes = useStyles()
  const { setSearchTerm: setSearchTermInStore, clearAllFilters } =
    useProductList()
  const [searchCounter, setSearchCounter] = useState(0)

  const clearSearchField = (): void => {
    setRawInputValue('')
    setThrottledInputValue('')
    setSearchCounter(0)
  }

  const clearFieldAndSearchTerm = (): void => {
    clearSearchField()
    setSearchTermInStore('')
    setOpen(false)
    setSearchCounter(0)
  }

  const doSelectSuggestion = (suggestion: string): void => {
    clearAllFilters(true)
    setSearchTermInStore(suggestion)
    setSearchCounter(0)
  }

  useEffect((): void => {
    if (focus && suggestions.length) {
      setOpen(true)
    }
  }, [suggestions.length, focus])

  return (
    <Autocomplete
      id="search-field"
      open={open}
      onOpen={() => {
        setFocus(true)
        if (suggestions.length) setOpen(true)
      }}
      onClose={() => {
        setFocus(false)
        setOpen(false)
      }}
      freeSolo
      noOptionsText={translate('search.noResults')}
      loadingText={translate('search.loading')}
      options={suggestions}
      loading={loadingData}
      onChange={(_event, newValue: null | string) => {
        doSelectSuggestion(newValue || '')
      }}
      onInputChange={(_event, newInputValue) => {
        setRawInputValue(newInputValue)
        setThrottledInputValue(newInputValue.trim())
        setSearchCounter(searchCounter + 1)
      }}
      inputValue={rawInputValue}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={translate('search.inputPlaceholder')}
          aria-label={translate('search.inputPlaceholder')}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
            ),
            classes: { root: classes.inputRoot },
            endAdornment: (
              <>
                {loadingData ? (
                  <Box mr={-4}>
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                ) : rawInputValue ? (
                  <Box mr={-4}>
                    <Button
                      onClick={clearFieldAndSearchTerm}
                      variant="text"
                      size="small"
                      className={classes.clearButton}
                    >
                      <CloseIcon className={classes.clearIcon} />
                    </Button>
                  </Box>
                ) : null}
              </>
            ),
          }}
        />
      )}
    />
  )
})
