import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { useProductList } from '../../store/product-list'
import { translate } from '../../helpers/translate'
import { ListFilters } from '../ListFilters'
import { Loading } from '../Loading'
import { CommunicationBreakdown } from '../CommunicationBreakdown'
import { ListSorting } from '../ListSorting'
import { FilterGroupWrapper } from '../FilterGroup'
import { FilterStatus } from '../FilterStatus'
import { Search } from '../Search'
import { FilterProduct } from '../../types/generated/sitecore.interface'
import { getSessionStorageItem } from '../../helpers/storage.helper'
import { getElementYPosition, scrollToYPosition } from '../../helpers/scroll'
import { ProductCard } from '../ProductCard'
import { FilterChips } from '../FilterChips/FilterChips'
import { GetHelp } from '../GetHelp'

const loadingSelector = state => state.loading
const errorSelector = state => state.error
const productSelector = state => state.products
const setQueryStringSelector = state => state.loadQueryString
const setSearchTermSelector = state => state.setSearchTerm
const searchTermSelector = state => state.searchTerm
const pageIndexSelector = state => state.page
const pageCountSelector = state => state.pageCount
const resultCountSelector = state => state.resultCount
const setPageSelector = state => state.setPage
const updateEverythingSelector = state => state.updateEverything

let firstRunComplete = false

const useStyles = makeStyles(theme => ({
  gridItem: {
    display: 'flex',
  },
  loadingState: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(255, 255, 255, .6)',
      zIndex: 1,
      pointerEvents: 'none',
    },
  },
  drawerContent: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '80vw',
      maxWidth: theme.spacing(45),
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: '40vw',
    },
  },
  statusBar: {
    margin: theme.spacing(-1),
    marginBottom: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  pagination: {
    marginTop: theme.spacing(5),
    textAlign: 'center',

    '& > *': {
      display: 'inline-flex',
    },
  },
  seeProductsButton: {
    marginTop: theme.spacing(2),
  },
  searchWrapper: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
}))

const ProductOverview: React.FC = React.memo((): JSX.Element => {
  const classes = useStyles()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const theme = useTheme()
  const isDesktopOrLarger = useMediaQuery(theme.breakpoints.up('md'))
  const gridContainerRef = React.createRef<HTMLDivElement>()
  const search = ''

  const listIsLoading = useProductList(loadingSelector)
  const listError = useProductList(errorSelector)
  const productList: FilterProduct[] = useProductList(productSelector)
  const setQueryString = useProductList(setQueryStringSelector)
  const setSearchTerm = useProductList(setSearchTermSelector)
  const currentSearchTerm = useProductList(searchTermSelector)
  const pageIndex = useProductList(pageIndexSelector)
  const pageCount = useProductList(pageCountSelector)
  const resultCount = useProductList(resultCountSelector)
  const setPage = useProductList(setPageSelector)
  const updateEverything = useProductList(updateEverythingSelector)

  useEffect(() => {
    updateEverything()
  }, [])

  useEffect((): void => {
    // We don't want to auto-scroll if the user has just opened up the page. We'll only do it once dynamic routing
    // starts happening.
    if (firstRunComplete) {
      const oldScrollPosition = getSessionStorageItem('overview-y')
      const lastKnownQueryString = getSessionStorageItem('lastQueryString')

      if (
        search.substr(1) === lastKnownQueryString &&
        oldScrollPosition &&
        oldScrollPosition !== '0'
      ) {
        scrollToYPosition(Number(oldScrollPosition))
      }
    } else {
      firstRunComplete = true
    }
  }, [])

  const changePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ): void => {
    setPage(value - 1)

    const targetPosition = getElementYPosition(
      gridContainerRef.current || undefined,
    )

    scrollToYPosition(targetPosition)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={8}>
          <Typography variant="h4" component="h1" gutterBottom>
            {translate('webshop')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.searchWrapper}>
          <Search />
        </Grid>
      </Grid>

      {listError && <CommunicationBreakdown reason={listError} />}

      <div className={classes.statusBar}>
        {!isDesktopOrLarger && (
          <>
            <Button
              onClick={() => setDrawerIsOpen(true)}
              variant="contained"
              type="button"
            >
              {translate('productFilters.filters')}
            </Button>
            <FilterChips />
            <Drawer
              anchor="left"
              open={drawerIsOpen}
              onClose={() => setDrawerIsOpen(false)}
            >
              <div className={classes.drawerContent}>
                {listIsLoading && (
                  <>
                    &nbsp;
                    <CircularProgress />
                  </>
                )}

                <FilterStatus />

                <ListFilters />

                <Box mt={1}>
                  <FilterGroupWrapper
                    fullWidth
                    content={<ListSorting />}
                    groupName={translate('sort')}
                  />
                </Box>

                <Button
                  onClick={() => setDrawerIsOpen(false)}
                  variant="contained"
                  className={classes.seeProductsButton}
                  fullWidth
                  size="large"
                >
                  {translate('showProductsButtonLabel').replace(
                    '%1',
                    resultCount.toString(),
                  )}
                </Button>
              </div>
            </Drawer>
          </>
        )}
      </div>

      <Grid
        container
        spacing={2}
        className={listIsLoading ? classes.loadingState : ''}
      >
        {isDesktopOrLarger && (
          <Grid item xs={12} md={3}>
            <>
              <FilterStatus />
              <ListFilters />
            </>
          </Grid>
        )}

        <Grid item xs={12} md={9} ref={gridContainerRef}>
          <Box mb={3}>
            <GetHelp wide />
          </Box>
          {listIsLoading && <Loading centered={productList.length > 0} />}
          {!productList.length && currentSearchTerm && (
            <Typography
              variant="body2"
              align="center"
              component="div"
              gutterBottom
            >
              {translate('noResults')}
            </Typography>
          )}
          <Grid container spacing={isDesktopOrLarger ? 2 : 1}>
            {productList.map(
              (product): JSX.Element => (
                <Grid
                  className={classes.gridItem}
                  item
                  xs={6}
                  sm={4}
                  key={product.Variants?.map(variant => variant.VariantNo).join(
                    ',',
                  )}
                >
                  {/* The product list doesn't include Network, which shows if it has 5g badge, so dont show here for now */}
                  <ProductCard has5GBadge={false} product={product} />
                </Grid>
              ),
            )}
          </Grid>

          {pageCount > 1 ? (
            <Pagination
              count={pageCount}
              page={pageIndex + 1}
              onChange={changePage}
              color="primary"
              size="medium"
              variant="outlined"
              className={classes.pagination}
            />
          ) : (
            <Box mt={5} />
          )}
        </Grid>
      </Grid>
    </>
  )
})

export default ProductOverview
