import { useEffect, useState } from 'react';
import { useDebounce } from './use-debounce';
import { searchSuggestions } from '../service/search-suggestions';

type ISearchSuggestionReturnData = {
    suggestions: string[];
    loading: boolean;
};

export const useSearchSuggestions = (searchTerm = ''): ISearchSuggestionReturnData => {
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const searchTermToUse = useDebounce(searchTerm.trim());

    const getSuggestions = async (term: string): Promise<void> => {
        if (term) {
            setLoading(true);
            try {
                const dataResult = await searchSuggestions(term);
                setSuggestions(dataResult);
            } catch {
                setSuggestions([]);
            }
            setLoading(false);
        }
    };

    useEffect((): void => {
        getSuggestions(searchTermToUse);
    }, [searchTermToUse]);

    return { suggestions, loading };
};
