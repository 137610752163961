import React from 'react'
import { useProductList } from '../../store/product-list'
import { FilterGroup, IFilterGroupProps } from '../FilterGroup'
import { translate } from '../../helpers/translate'
import {
  CircularProgress,
  List,
  ListItem as MuiList,
  ListItemText,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core'
import tdcColors from '@tdcerhverv/parrotfish/dist/colors-design-token.js'

const ignoredFacets = ['sort', 'pris', 'farve']

const useStyles = makeStyles(theme => {
  return {
    header: {
      marginBottom: theme.spacing(2),
    },
    list: {
      marginBottom: theme.spacing(2.5),
    },
  }
})

const ListItem = withStyles(theme => ({
  root: {
    borderRadius: '3px',
  },
  selected: {
    borderRadius: '3px',
    color: '#0000BF !important', // Could not find this color in the theme palette or tdcColors, yet it's there in the design
    backgroundColor: `${tdcColors.infoLight} !important`,
    '&.Mui-focusVisible': {
      // This should be set in the theme, not overriden like this
      color: `${theme.palette.background.paper} !important`,
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
}))(MuiList)

export const ListFilters: React.FC = () => {
  const { facets, setFacet, categories, selectCategory } = useProductList()
  const classes = useStyles()

  const onSetFacet: IFilterGroupProps['onFacetChange'] = (
    facetGroupName,
    facetOption,
    isChecked,
  ) => setFacet(facetGroupName, facetOption, isChecked)

  const activeCategoryIndex =
    categories?.FacetResults?.findIndex(facet => facet.IsSelected) ?? -1

  const visibleFacets = Object.values(facets)
    // Sorting is technically a facet, but we don't want it in the filter list just now.
    .filter(facet => !ignoredFacets.includes(facet.Key!.toLocaleLowerCase()))

  return (
    <aside>
      {categories ? (
        <List
          component="nav"
          aria-labelledby="category-list-header"
          subheader={
            <Typography
              id="category-list-header"
              className={classes.header}
              variant="h6"
              component="h2"
            >
              {translate('productCategories.header')}
            </Typography>
          }
          className={classes.list}
        >
          <ListItem
            button
            selected={activeCategoryIndex < 0}
            onClick={() => selectCategory('')}
          >
            <ListItemText
              primary={`${translate('productCategories.allCategories')}${
                categories.TotalFacetResultsCount
                  ? ` (${categories.TotalFacetResultsCount || 0})`
                  : ''
              }`}
            />
          </ListItem>
          {categories.FacetResults?.map(facet => (
            <ListItem
              key={facet.Query!.Value}
              button
              selected={facet.IsSelected}
              onClick={() => {
                selectCategory(facet.Query!.Value!)
              }}
            >
              <ListItemText primary={`${facet.Query?.Name} (${facet.Count})`} />
            </ListItem>
          ))}
        </List>
      ) : (
        <CircularProgress />
      )}
      {visibleFacets.map(facet => (
        <FilterGroup key={facet.Key} facet={facet} onFacetChange={onSetFacet} />
      ))}
    </aside>
  )
}
