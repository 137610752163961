import React from 'react';
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import { IProductListState, useProductList } from '../../store/product-list';
import { translate } from '../../helpers/translate';

const sortingSelector = (state) => state.sortInfo;
const setSortingSelector = (state) => state.sortBy;

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: 120,
    },
    select: {
        '& > div': {
            minWidth: 120,
        },
        '& > svg': {
            width: '1em',
            height: '1em',
        },
    },
}));

export const ListSorting: React.FC = React.memo((): JSX.Element | null => {
    const classes = useStyles();
    const sortData = useProductList<IProductListState['sortInfo']>(sortingSelector);
    const setSorting = useProductList<IProductListState['sortBy']>(setSortingSelector);

    if (!sortData || !sortData.FacetResults?.length) return null;

    const selectedFacet = sortData.FacetResults.find((facet) => facet.IsSelected);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSorting(event.target.value as string);
    };

    return (
        <FormControl className={classes.formControl} size="medium" fullWidth>
            <Select
                fullWidth
                id="list-sorting-selector"
                value={selectedFacet?.Query?.Value || ''}
                onChange={handleChange}
                aria-label={sortData.Name}
                variant="standard"
                className={classes.select}
            >
                {!selectedFacet?.Query?.Value && (
                    <MenuItem value="">
                        <em>{translate('sort')}</em>
                    </MenuItem>
                )}
                {sortData.FacetResults.map((facet) => (
                    <MenuItem value={facet.Query?.Value} key={facet.Query?.Value}>
                        {facet.Query?.Name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});
