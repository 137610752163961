import {
  ABORT_CONTROLLER_SUPPORTED,
  SEARCH_SUGGESTION_ENDPOINT,
} from '../../constants'
import { getErhvervsCenterAPIUrl } from '../helpers/api-url'

let abortController: AbortController | undefined

export const searchSuggestions = async (searchFor = ''): Promise<string[]> => {
  if (ABORT_CONTROLLER_SUPPORTED) {
    if (abortController) {
      abortController.abort()
    }

    abortController = new AbortController()
  }

  return await fetch(
    `${getErhvervsCenterAPIUrl()}${SEARCH_SUGGESTION_ENDPOINT}?search=${encodeURIComponent(
      searchFor,
    )}&count=5`,
    {
      signal: abortController?.signal,
    },
  ).then(response => response.json())
}
