import React from 'react'
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import { FilterGroupWrapper } from '../FilterGroup/FilterGroupWrapper'
import { FacetOutputViewModel } from '../../types/generated/sitecore.interface'

export type IFilterGroupProps = {
  facet: FacetOutputViewModel
  onFacetChange: (
    facetGroupName: string,
    facetOption: string,
    isChecked: boolean,
  ) => void
  hideCounter?: boolean
}

export const FilterGroup: React.FC<IFilterGroupProps> = React.memo(
  (props: IFilterGroupProps): JSX.Element | null => {
    const { facet, onFacetChange, hideCounter } = props

    const handleChange = event => {
      const { name, checked } = event.target
      onFacetChange(facet.Key || '', name, checked)
    }

    if (!facet.FacetResults || !facet.FacetResults.length) return null

    const visibleFacetResults = facet.FacetResults

    const activeFacets =
      !hideCounter &&
      facet.FacetResults.filter(option => option.IsSelected).length

    const content = (
      <>
        {facet.Control === 'Multicheck' &&
          visibleFacetResults.map(option => (
            <FormControlLabel
              key={option.Query?.Value}
              checked={option.IsSelected}
              onChange={handleChange}
              name={option.Query?.Value}
              control={<Checkbox />}
              label={`${option.Query?.Name} (${option.Count})`}
            />
          ))}
      </>
    )

    return (
      <FilterGroupWrapper
        content={content}
        groupName={
          activeFacets ? `${facet.Name} (${activeFacets})` : facet.Name || ''
        }
      />
    )
  },
)
