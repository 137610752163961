import React from 'react'
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  FormGroup,
  withStyles,
  SvgIcon,
} from '@material-ui/core'
import { useProductList } from '../../store/product-list'
import Expand_down from '@tdcerhverv/parrotfish/dist/icons/Icons/Expand_down.svg'

type IFilterGroupWrapperProps = {
  content: React.ReactNode
  groupName: string
  defaultExpanded?: boolean
  fullWidth?: boolean
}

const Accordion = withStyles(theme => ({
  root: {
    borderRadius: '6px !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
  },
}))(MuiAccordion)

const FormGroupFullWidth = withStyles({
  root: {
    width: '100%',
  },
})(FormGroup)

export const FilterGroupWrapper: React.FC<IFilterGroupWrapperProps> = (
  props: IFilterGroupWrapperProps,
): JSX.Element => {
  const listIsLoading = useProductList(state => state.loading)
  const { groupName, content, fullWidth } = props

  return (
    <Accordion defaultExpanded={props.defaultExpanded}>
      <AccordionSummary
        expandIcon={<SvgIcon viewBox="0 0 48 48" component={Expand_down} />}
      >
        {groupName}
        {listIsLoading && (
          <>
            &nbsp;
            <CircularProgress size="1.4rem" />
          </>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {fullWidth ? (
          <FormGroupFullWidth>{content}</FormGroupFullWidth>
        ) : (
          <FormGroup>{content}</FormGroup>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
